var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"jobOfferObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Język","label-for":"v-language-select"}},[_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages},on:{"input":_vm.fetchByLocale},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}})],1),_c('b-form-group',{attrs:{"label":"Tytuł"}},[_c('validation-provider',{attrs:{"name":"Tytuł","rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-image-title","placeholder":"Tytuł","disabled":!_vm.lang},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Treść")]),_c('b-form-group',{attrs:{"label":"Opis SEO"}},[_c('validation-provider',{attrs:{"name":"Opis SEO","rules":"required|min:3|max:160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":!_vm.lang,"rows":"3"},model:{value:(_vm.form.seo_description),callback:function ($$v) {_vm.$set(_vm.form, "seo_description", $$v)},expression:"form.seo_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tag"}},[_c('validation-provider',{attrs:{"name":"Tag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.lang},model:{value:(_vm.form.tag),callback:function ($$v) {_vm.$set(_vm.form, "tag", $$v)},expression:"form.tag"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Miasto"}},[_c('validation-provider',{attrs:{"name":"Miasto","rules":"max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.lang},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Wynagrodzenie"}},[_c('validation-provider',{attrs:{"name":"Wynagrodzenie","rules":"max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.lang},model:{value:(_vm.form.rate),callback:function ($$v) {_vm.$set(_vm.form, "rate", $$v)},expression:"form.rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Wymiar pracy"}},[_c('validation-provider',{attrs:{"name":"Wymiar pracy","rules":"max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.lang},model:{value:(_vm.form.working_time),callback:function ($$v) {_vm.$set(_vm.form, "working_time", $$v)},expression:"form.working_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Lewe zdjęcie"}},[_c('b-button',{attrs:{"disabled":!_vm.lang},on:{"click":function($event){return _vm.toggleShowModal('left')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Lewe zdjęcie","rules":"size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.left)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.left.file),expression:"files.left.file"}],attrs:{"type":"hidden","name":"Lewe zdjęcie"},domProps:{"value":(_vm.files.left.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.left, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.left),expression:"files.left"}],attrs:{"type":"hidden","name":"Lewe zdjęcie"},domProps:{"value":(_vm.files.left)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "left", $event.target.value)}}}),(_vm.showImagesModal.left)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.left},on:{"closeModal":function($event){return _vm.toggleShowModal('left')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'left'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.left)?_c('ImagePreview',{attrs:{"image":_vm.files.left},on:{"deleteImage":function () { return _vm.handleDelete('left'); }}}):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Prawe zdjęcie"}},[_c('b-button',{attrs:{"disabled":!_vm.lang},on:{"click":function($event){return _vm.toggleShowModal('right')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Prawe zdjęcie","rules":"size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.right)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.right.file),expression:"files.right.file"}],attrs:{"type":"hidden","name":"Prawe zdjęcie"},domProps:{"value":(_vm.files.right.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.right, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.right),expression:"files.right"}],attrs:{"type":"hidden","name":"Prawe zdjęcie"},domProps:{"value":(_vm.files.right)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "right", $event.target.value)}}}),(_vm.showImagesModal.right)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.right},on:{"closeModal":function($event){return _vm.toggleShowModal('right')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'right'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.right)?_c('ImagePreview',{attrs:{"image":_vm.files.right},on:{"deleteImage":function () { return _vm.handleDelete('right'); }}}):_vm._e()],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Obowiązki")]),_c('b-form-group',[_vm._l((_vm.form.responsibilities),function(item,index){return _c('b-col',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-form-group',{attrs:{"label":("Obowiązek #" + (index + 1))}},[_c('validation-provider',{attrs:{"name":("Obowiązek #" + (index + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Do obowiązków kandydata należeć będzie...","disabled":!_vm.lang},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeElement('responsibilities', index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Usuń")])],1),_c('hr')],1)}),_c('b-col',{staticClass:"mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.addElement('responsibilities')}}},[_c('span',[_vm._v("Dodaj obowiązek")])])],1)],2)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Benefity")]),_c('b-form-group',[_vm._l((_vm.form.benefits),function(item,index){return _c('b-col',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-form-group',{attrs:{"label":("Benefit #" + (index + 1))}},[_c('validation-provider',{attrs:{"name":("Benefit #" + (index + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Benefitem jest...","disabled":!_vm.lang},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeElement('benefits', index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Usuń")])],1),_c('hr')],1)],1)}),_c('b-col',{staticClass:"mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.addElement('benefits')}}},[_c('span',[_vm._v("Dodaj benefit")])])],1)],2)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Wymagania")]),_c('b-form-group',[_vm._l((_vm.form.requirements),function(item,index){return _c('b-col',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-form-group',{attrs:{"label":("Wymaganie #" + (index + 1))}},[_c('validation-provider',{attrs:{"name":("Wymaganie #" + (index + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Od kandydata wymagamy...","disabled":!_vm.lang},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeElement('requirements', index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Usuń")])],1),_c('hr')],1)],1)}),_c('b-col',{staticClass:"mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.addElement('requirements')}}},[_c('span',[_vm._v("Dodaj wymaganie")])])],1)],2)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Mile widziane")]),_c('b-form-group',[_vm._l((_vm.form.nice_to_have),function(item,index){return _c('b-col',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-form-group',{attrs:{"labe":("Mile widziane #" + (index + 1))}},[_c('validation-provider',{attrs:{"name":("Mile widziane #" + (index + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Mile widziane jest...","disabled":!_vm.lang},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeElement('nice_to_have', index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Usuń")])],1),_c('hr')],1)],1)}),_c('b-col',{staticClass:"mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.addElement('nice_to_have')}}},[_c('span',[_vm._v("Dodaj mile widziane")])])],1)],2)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }