<template>
  <validation-observer ref="jobOfferObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>

        <b-form-group label="Język" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            placeholder="Wybierz język"
            v-model="lang"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="languages"
            @input="fetchByLocale" />
        </b-form-group>

        <b-form-group label="Tytuł">
          <validation-provider #default="{ errors }" name="Tytuł" rules="required|min:3|max:100">
            <b-form-input id="v-image-title" placeholder="Tytuł" v-model="form.title" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- <b-form-group label="Zdjęcie">
          <b-button @click="toggleShowModal('title_image')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Logo" rules="required|size:5000">
            <input v-if="files.title_image" type="hidden" name="Logo" v-model="files.title_image.file" />
            <input v-else type="hidden" name="Logo" v-model="files.title_image" />

            <ImageSelector
              v-if="showImagesModal.title_image"
              :files="filesArray"
              :show-modal="showImagesModal.title_image"
              @closeModal="toggleShowModal('title_image')"
              @submit="(fileList) => handleFile(fileList[0], 'title_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.title_image"
            :image="files.title_image"
            @deleteImage="() => handleDelete('title_image')" />
        </b-form-group> -->
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Treść</h4>

        <b-form-group label="Opis SEO">
          <validation-provider #default="{ errors }" name="Opis SEO" rules="required|min:3|max:160">
            <b-form-textarea v-model="form.seo_description" :disabled="!lang" rows="3" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Tag">
          <validation-provider #default="{ errors }" name="Tag" rules="required">
            <b-form-input v-model="form.tag" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Miasto">
          <validation-provider #default="{ errors }" name="Miasto" rules="max:40">
            <b-form-input v-model="form.city" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Wynagrodzenie">
          <validation-provider #default="{ errors }" name="Wynagrodzenie" rules="max:40">
            <b-form-input v-model="form.rate" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Wymiar pracy">
          <validation-provider #default="{ errors }" name="Wymiar pracy" rules="max:40">
            <b-form-input v-model="form.working_time" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Lewe zdjęcie">
          <b-button @click="toggleShowModal('left')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Lewe zdjęcie" rules="size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.left" type="hidden" name="Lewe zdjęcie" v-model="files.left.file" />
            <input v-else type="hidden" name="Lewe zdjęcie" v-model="files.left" />

            <ImageSelector
              v-if="showImagesModal.left"
              :files="filesArray"
              :show-modal="showImagesModal.left"
              @closeModal="toggleShowModal('left')"
              @submit="(fileList) => handleFile(fileList[0], 'left')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview v-if="files.left" :image="files.left" @deleteImage="() => handleDelete('left')" />
        </b-form-group>

        <b-form-group label="Prawe zdjęcie">
          <b-button @click="toggleShowModal('right')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Prawe zdjęcie" rules="size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.right" type="hidden" name="Prawe zdjęcie" v-model="files.right.file" />
            <input v-else type="hidden" name="Prawe zdjęcie" v-model="files.right" />

            <ImageSelector
              v-if="showImagesModal.right"
              :files="filesArray"
              :show-modal="showImagesModal.right"
              @closeModal="toggleShowModal('right')"
              @submit="(fileList) => handleFile(fileList[0], 'right')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview v-if="files.right" :image="files.right" @deleteImage="() => handleDelete('right')" />
        </b-form-group>
      </b-col>

      <!-- RESPONSIBILITIES -->
      <b-col>
        <h4 class="mb-2 mt-3">Obowiązki</h4>

        <b-form-group>
          <b-col v-for="(item, index) in form.responsibilities" :id="item.id" :key="item.id" ref="row">
            <b-form-group :label="`Obowiązek #${index + 1}`">
              <validation-provider #default="{ errors }" :name="`Obowiązek #${index + 1}`" rules="required">
                <b-form-input
                  v-model="item.value"
                  placeholder="Do obowiązków kandydata należeć będzie..."
                  :disabled="!lang" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              size="sm"
              @click="removeElement('responsibilities', index)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Usuń</span>
            </b-button>
            <hr />
          </b-col>

          <b-col class="mt-2">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="addElement('responsibilities')">
              <span>Dodaj obowiązek</span>
            </b-button>
          </b-col>
        </b-form-group>
      </b-col>

      <!-- BENEFITS -->
      <b-col>
        <h4 class="mb-2 mt-3">Benefity</h4>

        <b-form-group>
          <b-col v-for="(item, index) in form.benefits" :id="item.id" :key="item.id" ref="row">
            <b-form-group :label="`Benefit #${index + 1}`">
              <validation-provider #default="{ errors }" :name="`Benefit #${index + 1}`" rules="required">
                <b-form-input v-model="item.value" placeholder="Benefitem jest..." :disabled="!lang" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-col>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                size="sm"
                @click="removeElement('benefits', index)">
                <feather-icon icon="XIcon" class="mr-25" />
                <span>Usuń</span>
              </b-button>
              <hr />
            </b-col>
          </b-col>

          <b-col class="mt-2">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="addElement('benefits')">
              <span>Dodaj benefit</span>
            </b-button>
          </b-col>
        </b-form-group>
      </b-col>

      <!-- REQUIREMENTS -->
      <b-col>
        <h4 class="mb-2 mt-3">Wymagania</h4>

        <b-form-group>
          <b-col v-for="(item, index) in form.requirements" :id="item.id" :key="item.id" ref="row">
            <b-form-group :label="`Wymaganie #${index + 1}`">
              <validation-provider #default="{ errors }" :name="`Wymaganie #${index + 1}`" rules="required">
                <b-form-input v-model="item.value" placeholder="Od kandydata wymagamy..." :disabled="!lang" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-col>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                size="sm"
                @click="removeElement('requirements', index)">
                <feather-icon icon="XIcon" class="mr-25" />
                <span>Usuń</span>
              </b-button>
              <hr />
            </b-col>
          </b-col>

          <b-col class="mt-2">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="addElement('requirements')">
              <span>Dodaj wymaganie</span>
            </b-button>
          </b-col>
        </b-form-group>
      </b-col>

      <!-- NICE TO HAVE -->
      <b-col>
        <h4 class="mb-2 mt-3">Mile widziane</h4>
        <b-form-group>
          <b-col v-for="(item, index) in form.nice_to_have" :id="item.id" :key="item.id" ref="row">
            <b-form-group :labe="`Mile widziane #${index + 1}`">
              <validation-provider #default="{ errors }" :name="`Mile widziane #${index + 1}`" rules="required">
                <b-form-input v-model="item.value" placeholder="Mile widziane jest..." :disabled="!lang" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-col>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                size="sm"
                @click="removeElement('nice_to_have', index)">
                <feather-icon icon="XIcon" class="mr-25" />
                <span>Usuń</span>
              </b-button>
              <hr />
            </b-col>
          </b-col>

          <b-col class="mt-2">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="addElement('nice_to_have')">
              <span>Dodaj mile widziane</span>
            </b-button>
          </b-col>
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import ImagePreview from '@/components/shared/ImagePreview.vue';
import ImageSelector from '@/components/shared/ImageSelector.vue';

import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin';

import { getLanguages } from '@/constants/languages';
import { base64Encode } from '@/constants/utils/file';

export default {
  mixins: [FileUploadMixin, NotificationMixin],
  props: {
    jobOffer: {
      type: Object,
      required: true,
    },
  },
  components: {
    ImagePreview,
    ImageSelector,
  },

  data() {
    return {
      languages: getLanguages(),
      lang: {
        lang: 'pl',
        value: 'Polski',
      },
      images: this.imagesPreviews,

      form: this.jobOffer,
    };
  },

  created() {
    this.setFileUploadData(['left', 'right'], ['left', 'right']);
  },

  watch: {
    async jobOffer() {
      await Promise.all([
        this.setImage(this.jobOffer.site_images[0], 'left'),
        this.setImage(this.jobOffer.site_images[1], 'right'),
      ]);

      this.form = this.jobOffer;
    },
  },

  methods: {
    onSubmit() {
      this.$emit('onSubmit', {
        ...this.form,
        site_images: [this.files.left, this.files.right],
        lang: this.lang.lang,
      });
    },
    fetchByLocale() {
      this.$emit('fetchByLocale', this.lang.lang);
    },
    validationForm() {
      this.$refs.jobOfferObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    removeElement(array, index) {
      this.form[array].splice(index, 1);
    },
    addElement(array) {
      this.form[array].push({
        id: Math.random(),
        value: '',
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
